import React from "react";

import Layout from "../components/layout";
import ChevronLink from '../components/chevronlink';
import SEO from "../components/seo";
import TripleImage from '../components/tripleimage';
import { Link } from "gatsby";

function Worried() {
  return (
    <Layout>
      <SEO
       title="Worried about Someone"
       description="For those who are worried about someone at risk of suicide and need to know how to help"
        />
      <div>
        <div className="container mx-auto px-2 md:py-24">
        <Link to="/"><div className="font-bold uppercase text-xs underline mb-4">Back home</div></Link>
          <div className="flex flex-col md:flex-row items-center md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
              <h2 className="text-3xl py-2 lg:pr-24 leading-tight">I'm worried about someone at risk of suicide and need to know how to help</h2>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">Identifying that someone is not feeling quite right and knowing how to help can be difficult. However, you don’t need to be a professional to check in on someone you are worried about. There is help available for you in starting the conversation and helping the person.</p>
              <p className="text-primary py-2 lg:pr-12 leading-relaxed">Be brave, reach out and start the conversation with someone you are worried about. Read on to learn more.</p>
            </div>
            <div className="w-full md:w-8/12 px-2 order-first md:order-none">
            <div className="p-4">
              <TripleImage 
                image1="5.jpg"
                image2="6.jpg"
                image3="4.jpg"
                mode={2}
               />
               </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container px-2  mx-auto py-12">
          <div className="flex flex-col md:flex-row md:-mx-2">
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box1 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Something to read</h3>
              <p className="text-primary py-2 leading-relaxed">For help in identifying the warning signs that someone may be having thoughts about suicide, <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/feeling-suicidal/suicidal-warning-signs" className="underline">click&nbsp;here.</a></p>
              <p className="text-primary py-2 leading-relaxed">For guidance on how to start the conversation, what to say and what not to say, <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/worried-about-someone-suicidal/having-a-conversation-with-someone-you're-worried-about" className="underline">click&nbsp;here.</a></p>
              <p className="text-primary py-2 leading-relaxed">For some people, it will be important they seek professional help. For support in raising this and encouraging the person to seek professional help, <a target="_blank" rel="noopener noreferrer" href="https://www.suicidecallbackservice.org.au/resource/discussing-suicide-how-to-talk-to-somebody-about-suicide/" className="underline">click&nbsp;here.</a></p>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box2 h-full p-6 rounded">
              <h3 className="text-2xl py-2 font-bold">Speak to someone</h3>
              <p className="text-primary py-2 leading-relaxed">A helpline can help to guide you in speaking to someone you are worried about.  A list of helplines you can contact is <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/community-services/sp-care-pathways/self-guided-support/helplines/" className="underline">available here.</a></p>
              <p className="text-primary py-2 leading-relaxed">You can also encourage the person you are concerned about to visit a GP. A GP can make a diagnosis, discuss suitable treatment options and refer them to a mental health professional if needed. You can search for a closely located General Practice by <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/general-practice-and-allied-health/general-practice/" className="underline">clicking&nbsp;here.</a></p>
            </div>
            </div>
            <div className="w-full md:w-4/12 px-2">
            <div className="bg-box3 h-full p-6 rounded">

            <h3 className="text-2xl py-2 font-bold">Access professional help</h3>
              <p className="text-primary py-2 leading-relaxed">A range of mental health services are available in the Brisbane North region.</p>
              <p className="text-primary py-2 leading-relaxed">For help in finding the service and supports that will best meet your friend or family member's needs, please contact the My Mental Health Service Navigators by calling <a className="whitespace-no-wrap" href="tel:1800752235">1800 752 235</a>.</p>
              <p className="text-primary py-2 leading-relaxed">Please note, this is not a crisis service and operates 8.30 am - 4.30 pm, Monday to Friday.</p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <section >
          <div className="container mx-auto">
            <div className="flex flex-col items-center p-4 md:pt-8 md:pb-16">
              <ChevronLink to="/lost-someone">I've lost someone to suicide and need help</ChevronLink>
          </div>
          </div>
      </section>
    </Layout>
  );
}

export default Worried;
